import { Button, Grid, Typography } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../wallet/connect";
import Web3 from "web3";

import SandboxLogo from "../../assets/img/sandbox-logo.png";

import "./header.scss";
import { useEffect, useState } from "react";

const Header = () => {
  const web3 = new Web3((window as any).ethereum);

  const { activate } = useWeb3React();
  const [address, setAddress] = useState<any>("");

  const connectWallet = async () => {
    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
    const address = await web3.eth.getAccounts();
    if (address[0]) {
      localStorage.setItem("ama-studios-address", address[0]);
      setAddress(address[0]);
    }
  };

  const checkConnected = async () => {
    const storedAddress = localStorage.getItem("ama-studios-address");
    if (storedAddress) {
      setAddress(storedAddress);
    }
  };

  useEffect(() => {
    checkConnected();
  }, []);

  // Listener
  window.addEventListener("load", () => {
    if ((window as any).ethereum) {
      // detect account change
      (window as any).ethereum.on("accountsChanged", async function () {
        const accounts = await web3.eth.getAccounts();
        if (accounts.length < 1) {
          localStorage.removeItem("ama-studios-address");
        }
        window.location.reload();
      });
    }
  });

  return (
    <div className="header">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <div className="header__text">
            <Typography className="header__text__amaStudios">
              AMA STUDIOS
              <span className="header__text__amaStudios__x">X</span>
              <img
                src={SandboxLogo}
                className="header__text__amaStudios__sandbox-logo"
                alt="sandbox-logo"
              />
            </Typography>
          </div>
        </Grid>
        <Grid item>
          {/* <div> */}
          <Button
            variant="outlined"
            className="header__button"
            onClick={connectWallet}
          >
            {address
              ? `${address.slice(0, 5)}...${address.slice(
                  address.length - 5,
                  address.length
                )}`
              : "CONNECT"}
          </Button>
          {/* </div> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
