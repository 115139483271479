import "./App.scss";
import { Button, Grid, Typography } from "@mui/material";
import Trailer from "./assets/video/trailer.mp4";
import Header from "./components/Header";
import { ReactElement, useEffect, useState } from "react";

const App = (): ReactElement => {
  const [mutedState, setMutedState] = useState(true);

  const onFocus = () => {
    setMutedState(false);
  };
  const onBlur = () => {
    setMutedState(true);
  };

  useEffect(() => {
    document.addEventListener("mousedown", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      document.removeEventListener("mousedown", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return (
    <div className="home">
      <div className="home__mobile__header">
        <Header />
      </div>
      <Grid container alignItems="center" spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <div className="home__videoContainer">
            <video
              autoPlay
              muted={mutedState}
              loop
              playsInline
              className="home__videoContainer__video"
            >
              <source src={Trailer} type="video/mp4" />
            </video>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          className="home__wrapper"
          style={{ margin: "auto" }}
        >
          <div className="home__wrapper__header">
            <Header />
          </div>
          <div className="home__wrapper__content">
            <Typography className="home__wrapper__content__text1">
              PIONEER IN WEB3 FASHION
            </Typography>
            <Typography className="home__wrapper__content__text2">
              Dressing your digital identity
            </Typography>
            <Typography className="home__wrapper__content__text3">
              There are a bewildering array of pieces to the Digital Ax project,
              from fractional garment ownership to a DAO (decentralized
              autonomous organization) comprising indie fashion designers and a
              new standard for virtual garments to be interoperable in the
              metaverse, so she has her work cut out for her. Luckily, she seems
              to enjoy it.{" "}
            </Typography>
            <Grid
              container
              gap={{ xs: 4, lg: 6 }}
              className="home__wrapper__content__buttons"
            >
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Button
                  variant="contained"
                  className="home__wrapper__content__buttons__raffle"
                >
                  JOIN THE RAFFLE
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Button
                  variant="outlined"
                  className="home__wrapper__content__buttons__whitepaper"
                >
                  WHITEPAPER
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
